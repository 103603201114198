<template>
  <div class="menucate-cls new-catg">
    <div class="rmvecls " :id="'menucateID-' + menu.id" @click="getActiveMenuId(menu.id)"
      v-if="this.$route.name == 'restaurant'">
      <img :src="menu.image" class="menu-cat-img" @error="$event.target.src = noImge">
      <h4>{{ menu.name }}</h4>
    </div>
    <!------------Menu Category 1 Start -------------------->
    
    <div v-if="cateMenuLayout == '1' && pagename == 'home' && menu.image == ''"
      class="resturant-item busines-img-box d-flex align-items-start"
      :style="{ backgroundColor: 'var(--theme-button-secondary-color)  !important', backgroundSize: '100% !important', backgroundPosition: 'center !important' }">
      <div class="busines-img">
        <div class="resturant-data ms-3 89">
          <h4
            :style="{ color: (menu.text_color_code == null ? 'var(--theme-font-primary)' : menu.text_color_code) + '!important' }">
            {{ menu.name }}</h4>
        </div>
      </div>
    </div>
    
    <div v-if="cateMenuLayout == '1' && pagename == 'home' && menu.image != ''"
      class="resturant-item busines-img-box d-flex align-items-start"
      :style="{ background: 'url(' + menu.image + ')!important', backgroundSize: '100% !important', backgroundPosition: 'center !important' }">
      <div class="busines-img">
        <div class="resturant-data ms-3">
          <h4 :style="{ color: menu.text_color_code + '!important' }">{{ menu.name }}</h4>
        </div>
      </div>
    </div>
    <!------------Menu Category 1 Start -------------------->
    <!------------Menu Category 2 Start -------------------->
    <div v-if="cateMenuLayout == '2' && pagename == 'home'" class="resturant-item busines-img-box"
      :style="{ background: menu.color_code + '!important' }">
      <div class="busines-img">
        <div class="img-box">
          <img :src="menu.image" @error="$event.target.src = noImge">
        </div>
        <div class="resturant-data ms-3">
          <h4 :style="{ color: menu.text_color_code + '!important' }">{{ menu.name }}</h4>
        </div>
      </div>
    </div>
    <!------------Menu Category 2 end -------------------->

    <!------------Menu Category 3 Start -------------------->
    <div v-if="cateMenuLayout == '3' && pagename == 'home'" class="resturant-item busines-img-box 69">
      <div class="busines-img">
        <div class="img-box">
          <img :src="menu.image" @error="$event.target.src = noImge">
        </div>
        <div class="resturant-data ms-3">
          <h4 :style="{ color: menu.text_color_code + '!important' }">{{ menu.name }} </h4>
        </div>
      </div>
    </div>
    <!------------Menu Category 3 end -------------------->

    <!------------Menu Category 4 Start -------------------->
    <div v-if="cateMenuLayout == '4' && pagename == 'home'" class="resturant-item busines-img-box"
      :style="{ background: menu.color_code + '!important' }">
      <div class="busines-img">
        <div class="resturant-data ms-3">
          <h4 :style="{ color: menu.text_color_code + '!important' }">{{ menu.name }}</h4>
        </div>
        <div class="img-box">
          <img :src="menu.image" @error="$event.target.src = noImge">
        </div>
      </div>
    </div>
    <!------------Menu Category 4 end -------------------->
    <!------------Menu Category 5 Start -------------------->
    <div v-if="cateMenuLayout == '5' && pagename == 'home'" class="resturant-item busines-img-box"
      :style="{ background: menu.color_code + '!important' }">
      <div class="busines-img">
        <div class="img-box">
          <img :src="menu.image" @error="$event.target.src = noImge">
        </div>
        <div class="resturant-data ms-3">
          <h4 :style="{ color: menu.text_color_code + '!important' }">{{ menu.name }}</h4>
        </div>
      </div>
    </div>
    <!------------Menu Category 5 end -------------------->
    <!------------Menu Category 6 Start -------------------->
    <div v-if="cateMenuLayout == '6' && pagename == 'home'" class="resturant-item busines-img-box"
      :style="{ background: menu.color_code + '!important' }">
      <div class="busines-img">
        <div class="resturant-data ms-3 dd">
          <h4 :style="{ color: menu.text_color_code + '!important' }">{{ menu.name }}</h4>
        </div>
        <div class="img-box">
          <img :src="menu.image" @error="$event.target.src = noImge">
        </div>
      </div>
    </div>
    <!------------Menu Category 6 end -------------------->
  </div>
</template>
<script>
import noImge from "@/assets/images/place_holder.png";
export default {

  props: ["menu", "cateMenuLayout", "pagename"],
  data() {
    return {
      loading: true,
      noImge,
    };
  },
  methods: {
    getActiveMenuId(id) {
      let element = document.getElementById('menucateID-' + id);
      $(".rmvecls").removeClass("active");
      element.className += " active";
      this.$emit("setCategoryMenuId", id);
    }
  }
}
</script>